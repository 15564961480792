const CLASS_SELECTOR = ".CollapsibleActivator";
const CHILD_SELECTOR = ".CollapsibleActivatorItem";
const CLASS_ACTIVE = "is-active";
const CLASS_INITIALIZED = "is-initialized";

function initialize(RootElement) {
    RootElement.classList.add(CLASS_INITIALIZED);
    const ChildElements = RootElement.querySelectorAll(CHILD_SELECTOR);

    if (ChildElements && ChildElements.length > 0) {
        const targetSelector = RootElement.getAttribute('data-target-selector');
        const TargetElements = targetSelector ? document.querySelectorAll(targetSelector) : null;
        const ChildSyncHandlers = [];
        if (TargetElements && TargetElements.length !== ChildElements.length) {
            console.warn(`CollapsibleActivator has mismatched element count (items=${ChildElements.length}, targets=${TargetElements.length})`, RootElement);
        }

        let lastSyncTimestamp;
        function sync() {
            ChildSyncHandlers.forEach((handler) => handler());
        }
        function syncElements(timestamp) {
            if (timestamp !== lastSyncTimestamp) {
                lastSyncTimestamp = timestamp;
                sync();
            }
        }

        function activateElements(index) {
            ChildElements.forEach((element, i) => {
                element.classList.toggle(CLASS_ACTIVE, i === index);
            });
            if (TargetElements && TargetElements.forEach) {
                TargetElements.forEach((element, i) => {
                    element.classList.toggle(CLASS_ACTIVE, i === index);
                });
            }
        }

        ChildElements.forEach((element, index) => {
            const ElementWrapper = element.querySelector('.CollapsibleActivatorItem-wrapper');
            const ElementContent = element.querySelector('.CollapsibleActivatorItem-content');

            element.addEventListener('click', (e) => {
                e.stopPropagation();
                activateElements(index);
            });

            ChildSyncHandlers.push(() => {
                ElementWrapper.style.height = ElementContent.offsetHeight + 'px';
            });
        });
        window.addEventListener('resize', () => {
            requestAnimationFrame(syncElements);
        });
        sync();
    }
    else {
        console.warn('Attempted to initialize a CollapsibleActivator with no child elements:', RootElement);
    }
}

function init(PageComponents, container=null) {
    if (!container || !container.querySelectorAll) {
        container = document;
    }
    const elements = container.querySelectorAll(CLASS_SELECTOR);
    elements.forEach((element) => {
       if (!element.classList.contains(CLASS_INITIALIZED)) initialize(element);
    });
}

module.exports = {init};

