const ATTRIBUTE_TOGGLE = "data-prg-reference",
    ATTRIBUTE_TARGET = "data-prg-index",
    CLASSNAME_CURRENT_TARGET = "current";

const PrimeResurgence = {
    ScoreBoard: null,
    timeRemaining: null,
    RotationGridToggles: null,
    RotationGridTargets: null,
    initCountdown() {
        let self = PrimeResurgence,
            secondMilliseconds,
            minuteMilliseconds,
            hourMilliseconds,
            daysMilliseconds,
            elDaysDisplay,
            elHoursDisplay,
            elMinutesDisplay,
            elSecondsDisplay,
            recursiveLoop;

        self.ScoreBoard = document.querySelector( "[ data-reference = 'scoreboard' ]" );
        self.timeRemaining = window.Warframe?.Data?.PrimeResurgence?.eoy202412?.remaining;

        if ( !self.ScoreBoard ) {
            return;
        }

        if ( !self.timeRemaining ) {
            self.ScoreBoard.style.display = "none";
            return;
        }

        secondMilliseconds = 1000;
        minuteMilliseconds = secondMilliseconds * 60;
        hourMilliseconds = minuteMilliseconds * 60;
        daysMilliseconds = hourMilliseconds * 24;

        elDaysDisplay = self.ScoreBoard.querySelector( ".days .display" );
        elHoursDisplay = self.ScoreBoard.querySelector( ".hours .display" );
        elMinutesDisplay = self.ScoreBoard.querySelector( ".minutes .display" );
        elSecondsDisplay = self.ScoreBoard.querySelector( ".seconds .display" );

        recursiveLoop = window.setInterval( () => {
            elDaysDisplay.textContent = self.formatDisplayNumber( self.timeRemaining / daysMilliseconds );
            elHoursDisplay.textContent = self.formatDisplayNumber( ( self.timeRemaining % daysMilliseconds ) / hourMilliseconds );
            elMinutesDisplay.textContent = self.formatDisplayNumber( ( self.timeRemaining % hourMilliseconds ) / minuteMilliseconds );
            elSecondsDisplay.textContent = self.formatDisplayNumber( ( self.timeRemaining % minuteMilliseconds ) / secondMilliseconds );

            if ( self.timeRemaining < secondMilliseconds ) {
                window.clearInterval( recursiveLoop );
            }
            self.timeRemaining -= secondMilliseconds;
        }, secondMilliseconds );
    },
    formatDisplayNumber( number ) {
        return String( Math.floor( number ) ).padStart( 2, "0" );
    },
    onClickToggle( event ) {
        event.preventDefault();
        let self = PrimeResurgence,
            elToggle = event.target.closest( `[ ${ ATTRIBUTE_TOGGLE } ]` ),
            reference = elToggle.getAttribute( ATTRIBUTE_TOGGLE ),
            elTarget = document.querySelector( `[ ${ ATTRIBUTE_TARGET }="${ reference }" ]` );

        if ( !elTarget ) { return; }
        if ( elTarget.classList.contains( CLASSNAME_CURRENT_TARGET ) ) { return; }

        self.RotationGridTargets.forEach( ( element, index ) => {
            element.classList.remove( CLASSNAME_CURRENT_TARGET );
        } );

        elTarget.classList.add( CLASSNAME_CURRENT_TARGET );
        elTarget.scrollIntoView( { behavior: "smooth" } );

    },
    initToggles() {
        let self = PrimeResurgence;
        self.RotationGridToggles = [ ... document.querySelectorAll( `[ ${ ATTRIBUTE_TOGGLE } ]` ) ];
        self.RotationGridTargets = [ ... document.querySelectorAll( `[ ${ ATTRIBUTE_TARGET } ]` ) ];

        if ( !self.RotationGridToggles ) {
            return;
        }

        self.RotationGridToggles.forEach( ( element, index ) => {
            element.addEventListener( "click", self.onClickToggle );
        } );

    },
    init: (PageComponents, container=null) => {
        if (!container || !container.querySelectorAll) {
            container = document;
        }
        let self = PrimeResurgence;

        self.initCountdown();
        self.initToggles();

        PageComponents.PrimeResurgence = PrimeResurgence;
    },
};

export { PrimeResurgence };
